<template>
  <!-- <RouterView name="modals" /> -->
  <ModalMedia />
  <BaseHead />

  <main class="default-main grid pb-10 overflow-a" :class="[isMobile ? 'pt-20' : 'pt-10']">
    <div class="content grid" :class="[isMobile ? 'gtc-1' : 'gtc-auto-1-auto ggap-20']">
      <BaseNav v-if="!isMobile" />
      <RouterView />
      <BaseSidebar v-if="!isMobile" />
    </div>
  </main>
</template>

<script setup>
import { computed } from 'vue'
import { BaseHead, BaseNav, BaseSidebar, ModalMedia } from '@/components'
import { useStore } from 'vuex'

// Data
const store = useStore()
const isMobile = computed(() => store.getters.isMobile)
</script>

<style lang="scss" scoped>
.default-main {
  height: calc(100vh - 50px);
}
</style>
